.ResumeComponent {
    padding: 10px 0;
}

.resume-download-div {
    margin-top: 10px;
}

.resume-download-button {
    font-size: var(--h6-font-size);
    float: right;
    color: var(--primary-color);
    outline: 1px solid var(--primary-color);
}

.resume-download-button:hover,
.resume-download-button:focus,
.resume-download-button:active,
.resume-download-button:before,
.resume-download-button:after {
    color: var(--primary-color);
    outline: 1px solid var(--primary-color);
}

.resume-content-div {
    border: 1px var(--primary-color);
    margin-top: 35px;
}

.section-title {
    text-transform: uppercase;
    color: var(--primary-color);
    font-size: var(--h4-font-size);
    font-weight: 600;
}

.section-title-link {
    text-decoration: none;
    color: var(--primary-color);
}

.section-title-link:hover {
    color: var(--primary-color);
}

.list-ul {
    padding: 1rem;
}

.list-item {
    list-style-type: none;
    margin-bottom: 20px;
}

/*general styles for list item*/
.item-image-div {
    overflow: hidden; /* hides any part of the image that overflows the container */
}

.item-image {
    width: 4rem;
    object-fit: cover;
    padding: 10px 0;
    border-radius: 25%;
}

.item-content-div {
}

.item-content-div p {
    margin: 0;
}

.item-title {
    color: var(--primary-color);
    font-size: var(--h5-font-size);
    text-decoration: none;
}

.item-title a {
    color: var(--primary-color);
    font-size: var(--h5-font-size);
    text-decoration: none;
}

.item-organization {
    font-size: var(--h6-font-size);
    color: var(--secondary-color);
}

.item-organization a {
    text-decoration: none;
    color: var(--secondary-color);
}

.item-date {
    font-size: var(--h6-font-size);
    color: var(--secondary-color);
}

.item-description {
    font-size: var(--h6-font-size);
    color: var(--secondary-color);
    text-align: justify;
    text-justify: inter-word;
    padding-left: 1rem;
}