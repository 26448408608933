.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    /*border-bottom: 1px solid #aaa;*/
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: var(--h5-font-size);
}

.react-tabs__tab--selected {
    background: transparent;
    /*border-color: #aaa;*/
    color: var(--primary-color);
    border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 1px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: var(--primary-color);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}
