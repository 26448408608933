.ResumeHeaderComponent {
}

.header-container {
    padding: 15px 0;
    text-align: center;
    color: var(--secondary-color);
}

.header-container h1 {
    font-size: var(--h3-font-size);
}

.header-container h5 {
    font-size: var(--h4-font-size);
}

.information-container {
    font-size: var(--h6-font-size);
}

.information-container a {
    text-decoration: none;
    color: var(--secondary-color);
}