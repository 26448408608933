/*.Footer {*/
/*    !*background-color: var(--primary-color);*!*/
/*    text-align: center;*/
/*}*/

.copyright {
    margin: 15px 0;
    text-align: center;
    font-size: 13px;
    color: var(--copiright-color);
}

/*custom footer*/
.footer-basic {
    padding: 8px 0 0 0;
    color: var(--secondary-color);
}
