.banner {
    background: var(--banner-background);
}

.banner-content {
    padding: 16px;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.banner-text {
    flex-grow: 0.5;
    line-height: 1.4;
}

.banner-text a {
    text-decoration: none !important;
    color: var(--banner-text);
}

.banner-close-button {
    background: none;
    border: none;
    cursor: pointer;
}
