.MediumArticles {
    color: var(--secondary-color);
    margin: 5vh 0;
}

.view-button-group {
    /*float: right;*/
}

.grid-button {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.grid-button:active, .grid-button:hover, .grid-button:focus, .grid-button:after {
    color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    background: var(--primary-color);
}

.card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-start;
    /*gap: 1em;*/
}

.flex-card-item {
    width: 380px;
}

.flex-list-item {
    width: 80vw;
}

.visit-div {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
}

.medium-tag-div {
    font-size: 0.8rem;
}

.medium-tag-div a {
    color: var(--secondary-color);
    text-decoration: none;
}

.read-more-link {
    text-decoration: none;
    align-items: center;
    font-size: 0.9rem;
    color: var(--primary-color);
}

.visit-h5 {
    align-content: center;
    font-size: 1.2rem;
    color: var(--primary-color);
}

.visit-h5 a {
    text-decoration: none;
    color: var(--primary-color);
    font-style: italic;
}

/*text*/
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
}

.new-card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
}

.new-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.new-card p {
    font-size: 16px;
    line-height: 1.5;
}
