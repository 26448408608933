.MediumCard {
}

.medium-card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.medium-card-image-div {
    height: 200px;
    /*padding: 10px;*/
    background-size: 100%;
    border-radius: 0.375rem 0.375rem 0 0;
}
