.SummaryProjectComponent {
}

.project-type {
    color: var(--secondary-color);
}

.project-item-image-div {
    padding: 10px 0;
    /*height: 200px;*/
}

.project-item-image {
    height: 200px;
    object-fit: contain;
    padding: 10px 0;
}

.project-item-card {
    /*border: 1px solid var(--tertiary-color);*/
    /*border-radius: 10px;*/
}

.project-item-card-content {
    /*height: 200px;*/
    /*border: 1px solid green;*/
    overflow-x: hidden;
    overflow-y: scroll;
}