.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.background-image {
    /*background-image: url("../public/img/bg.png");*/
    background-size: cover; /* This property ensures the image fills the entire background */
    background-repeat: no-repeat; /* This property ensures the image isn't repeated in the background */
    background-position: center; /* This property centers the image horizontally and vertically in the background */
}

:root {
    --primary-color: #E26313;
    --secondary-color: #232E44;
    --tertiary-color: #232E44;
    --background-color: #F9F9F8;
    --background-highlight-color: #F9F9F8;
    --primary-gradient: linear-gradient(to right, var(--primary-color) 0%, #92B1F5 50%, var(--primary-color) 100%);
    --h1-font-size: 60px;
    --h2-font-size: 3.5rem;
    --h3-font-size: 36px;
    --h4-font-size: 1.3rem;
    --h5-font-size: 16px;
    --h6-font-size: 14px;
    --p-font-size: 1.15rem;
    --card-shadow: #32325D3F 2px 2px 6px -1px, #0000004C 1px 2px 2px -2px;
    --card-boarder: #33333311;
    --copiright-color: #aaaaaa;
    --button-color: #F9FAFB;
    --banner-background: #E27429;
    --banner-text: #FFFFFF;
}

[data-theme="dark"] {
    --primary-color: #E27429;
    --secondary-color: #F9F9F8;
    --tertiary-color: #232E44;
    --background-color: #0F0E17;
    --background-highlight-color: #0A0913;
    --primary-gradient: linear-gradient(to right, var(--primary-color) 0%, #273f6e 50%, var(--primary-color) 100%);
    /*--card-shadow: #b3b3b33f 0px 0px 5px -1px, #9d9d9d4c 1px 2px 5px -2px;*/
    --card-boarder: #33333311;
    --copiright-color: #aaaaaa;
}

.primary-gradient {
    background-image: var(--primary-gradient);
    -webkit-background-clip: text;
    -moz-background-clip: inherit;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /*font-family: 'Roboto', sans-serif !important;*/
    /*font-family: 'Montserrat', sans-serif;*/
    /*font-family: 'Noto Sans', sans-serif !important;*/
    font-family: 'Poppins', sans-serif !important;
}

body {
    background-color: var(--background-color);
}

.primary-button {
    /*background-image: var(--primary-gradient);*/
    background: var(--primary-color);
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: var(--button-color);
    /*box-shadow: 0 0 20px #eee;*/
    border-radius: 5px;
    display: block;
}

.primary-button:hover {
    /*background-position: right center; !* change the direction of the change here *!*/
    background: var(--primary-color);
    text-decoration: none;
    color: var(--button-color);
    border: 1px solid var(--background-color);
}

.primary-button:focus,
.primary-button:active,
.primary-button:before,
.primary-button:after {
    border: none !important;
    box-shadow: none !important;
    color: var(--button-color);
    background: var(--primary-color);
}

.div-spacer {
    height: 10px;
}

.div-hr {
    width: 85%;
    margin: 10px auto;
}

/*404 page*/
.page-404-title {
    color: var(--primary-color);
}

.page-404-sub-title {
    color: var(--primary-color);
}

.page-404-body {
    color: var(--secondary-color);
}

::-webkit-scrollbar {
    display: none;
}

.error-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error-text {
}