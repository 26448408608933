.App-header {
    margin: 1rem 0;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.header-text-container {
    margin: 20px 0;
}

.header-name-h2 {
    font-size: var(--h2-font-size);
    color: var(--secondary-color);
    font-weight: 500;
    line-height: 1;
}

.header-name {
    color: var(--primary-color);
    /*background: var(--primary-gradient);*/
    /*background-size: 200%;*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
}

.header-title-p {
    color: var(--secondary-color);
    font-weight: 500;
    font-size: var(--h4-font-size);
    line-height: 2.25rem;
}

.header-about-p {
    color: var(--secondary-color);
    font-size: var(--p-font-size);
    line-height: 2rem;
    /*font-weight: 400;*/
}

.header-about-p a {
    text-decoration: none;
    color: var(--secondary-color);
    font-style: italic;
}


.header-cert-img-custom {
    max-height: 65px;
    margin: 0 10px;
}

.header-social-div {
    margin-top: 15px;
}

.header-social-title {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    color: var(--primary-color);
    font-size: var(--h6-font-size);
    line-height: 21px;
    font-weight: 500;
}

/*---------*/
.social-share {
    list-style: none;
    flex-wrap: wrap;
    padding: 0 !important;

}

.social-share li {
    margin: 10px 14px;
    /*background: var(--background-highlight-color);*/
    border-radius: 10px;
    width: 1.5rem;
    text-align: center;
    /*box-shadow: var(--card-shadow);*/
}

.social-share li a {
    line-height: 3.5rem;
    color: var(--primary-color);
    font-size: 1.2rem;
}

.social-share li a:hover {
    color: var(--secondary-color);
    font-size: 1.5rem;
}

.carousel-container {
    margin: 25px 0;
}

.circle-container {
    padding: 2vw;
}

.circle {
    position: relative;
    width: 100%;
    max-width: 400px; /* This sets a maximum width for the circle div */
    padding-bottom: 100%; /* This creates a 1:1 aspect ratio by setting the padding to 100% of the element's width */
    border-radius: 50%; /* This sets the border radius to 50% to create a circle */
    overflow: hidden; /* This hides any content outside of the circle */

}

.circle img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire circle */
    object-position: center center; /* This centers the image in the circle */
}


.avatar-div {
    background-image: linear-gradient(0deg, var(--background-color), var(--primary-color));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    /*width: 60vh;*/
    /*margin: 10vh auto;*/
    box-shadow: var(--card-shadow);
}

.avatar-img {
    /*width: 60vh;*/
}

@media (max-width: 576px) {
    .avatar-div {
        width: 80vw;
        height: 80vw;
        margin: 2vw auto;
    }

    .avatar-img {
        width: 80vw;
    }
}

@media (min-width: 1024px) {
    .avatar-div {
        width: 30vw;
        height: 30vw;
        margin: 5vw auto;
    }

    .avatar-img {
        width: 30vw;
    }
}

@media (min-width: 1360px) {
    .avatar-div {
        width: 25vw;
        height: 25vw;
        margin: 1vw auto;
    }

    .avatar-img {
        width: 25vw;
    }
}

@media (min-width: 1440px) {
    .avatar-div {
        width: 18vw;
        height: 18vw;
        margin: 1vw auto;
    }

    .avatar-img {
        width: 18vw;
    }
}

@media (min-width: 2560px) {
    .avatar-div {
        width: 14vw;
        height: 14vw;
        margin: 1vw auto;
    }

    .avatar-img {
        width: 14vw;
    }
}

@media (min-width: 3840px) {
    .avatar-div {
        width: 10vw;
        height: 10vw;
        margin: 0 auto;
    }

    .avatar-img {
        width: 10vw;
    }
}
