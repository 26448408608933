.navbar {
    transition: top 0.3s;
}

.navbar.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.navbar-custom {
    background: var(--background-color);
    box-shadow: var(--card-shadow);
}

.navbar-brand {
    /* height: 40px; */
}

.navbar-brand-img-custom {
    /* width: 100%; */
    /* height: 190%; */
    /* margin-top: -10px; */
    /* height: 37px; */
    width: 54px;
    margin: -12px 0;
}

.btn-link {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.btn-link:focus,
.btn-link:active,
.btn-link:before,
.btn-link:after {
    border: none !important;
    box-shadow: none !important;
}

.theme-button {
    font-size: 1.3rem;
    text-decoration: none;
}

.nav-dropdown {
    margin-left: auto;
}

/*-----*/
.navbar-link-div {
    margin-left: auto;
}

.navbar-link-div-mobile {
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
}

.navbar-link {
    font-size: var(--h6-font-size);
    color: var(--secondary-color);
    text-decoration: none !important;
    padding: 0 1rem;
}

.navbar-link:hover {
    color: var(--primary-color);
}

.nav-bar-browser-view {
    position: relative;
    float: right;
}

.nav-bar-mobile-view {
    position: relative;
    width: 100%;
}