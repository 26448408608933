.CarouselCard {
    margin: 10px 15px;
}

.custom-card {
    border: 1px solid var(--card-boarder);
    box-shadow: var(--card-shadow);
    background-color: var(--background-highlight-color);
    border-radius: 0.375rem;
}

.custom-card-image-div {
    height: 22vh;
    /*padding: 10px;*/
    background-size: 100%;
    border-radius: 0.375rem 0.375rem 0 0;
}

.custom-card-image {
    object-fit: contain;
    height: 18vh;
    width: 95%;

    display: block;
    margin: 0 auto;
}

.custom-card-body {
    /*width: 18rem;*/
    height: 10vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 10px;
}

.custom-card-title {
    color: var(--primary-color);
    font-weight: 700;
    /*text-transform: uppercase;*/
    text-decoration: none;
    font-size: 1rem;
}

.custom-card-title:hover {
    color: var(--primary-color);
}

.custom-card-text {
    color: var(--secondary-color);
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
}

.custom-card-button {
    width: 100%;
    margin: 10px 0;
}

.custom-card-button-li {
    background-color: var(--background-highlight-color);
}
