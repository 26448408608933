.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    background-color: var(--primary-color);
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
    width: 40px;
    height: 40px;
}

.scroll-to-top-button {
    color: var(--background-color);
}

.scroll-to-top-button:hover {
    opacity: 1;
}

.scroll-to-top-icon {
    font-size: 1.5rem;
}
