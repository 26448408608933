.MediumListItem {
    /*width: 50vh;*/
}

.list-card-body {
    overflow: scroll;
    margin: 10px;
}

.list-card-image-div {
    height: 100%;
    /*padding: 10px;*/
    background-size: 100%;
    border-radius: 0 0.375rem 0.375rem 0;
    background-repeat: no-repeat;
}
