.CoverLetterComp {
    padding: 20px;
}

.input-label {
    color: var(--secondary-color);
}

.input-field,
.input-field:focus,
.input-field:active,
.input-field:after {
    color: var(--secondary-color);
    background-color: var(--background-highlight-color);
    /*border: none;*/
}

.copy-button,
.copy-button:hover,
.copy-button:focus,
.copy-button:active,
.copy-button:before,
.copy-button:after {
    /*margin-top: 10px;*/
    outline: none;
    border: none;
    /*margin-top: -35px;*/
    color: var(--secondary-color);
}

.custom-alert {
    text-align: center;
    padding: 4px;
}
